body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f5f5;
}

footer {
  color: #fff;
  background-color: #3a3a3a;
  padding: 0.5em;
}

footer a {
  text-decoration: none;
}

.centering {
  text-align: center;
}

.right {
  text-align: right;
}

.m10 {
  margin: 10px;
}

.mr10 {
  margin-right: 10px;
}

.small {
  font-size: small;
}

.flex-left-right {
  display: flex;
  justify-content: space-between;
}

.panel {
  background-color: #ffffff;
  /*border: 4px solid #3a3a3a;*/
  border-radius: 8px;
  /*margin: 20px 10px;*/
  padding: 15px 25px
}

.flex-base {
  display: flex;
  max-width: 1280px;
  margin: auto;
}

.flex-main {
  flex-grow: 1;
}

.flex-sub {
  flex-basis: 500px;
}

.flex-sub-sub {
  flex-basis: 250px;
}

.w100 {
  width: 100%;
}

.btnPrimary {
  color: #fff !important;
  /*background-color: rgb(138, 199, 122) !important;*/
  background-color:  rgb(140, 195, 88) !important;
  /*border-bottom: solid 3px #79b369 !important;*/
  text-decoration: none !important;
  border-radius: 5px !important;
  /*box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;*/
}

.btnPrimary:active {
  transform: translateY(3px) !important;
  border-bottom: none !important;
}

.btnPrimary:disabled {
  /*transform: translateY(3px) !important;*/
  background-color: lightgray !important;
}

@media screen and (max-width: 55em) {
  .panel {
    margin: 20px 0;
  }

  .flex-base {
    flex-direction: column;
  }

  .flex-right {
    flex-grow: 1;
  }
}

.div-content {
  min-height: calc(100vh - 184px);
}

.geniam-btn {
  background-color: #1790FF;
  color: white;
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  min-width: 80px;
  border-radius: 4px;
  border: none !important;

}

.geniam-btn:hover {
  transform: scale(1.1);
}

.mb-20 {
  margin-bottom: 20px !important;
}

.cart-view-container {
  max-width: 565px;
  margin: 0 auto 50px auto;
}

.cart-view-container .tab-active {
  cursor: pointer;
  color: rgba(140, 195, 88, 1);
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 4px #8CC358;
  margin: 0 4px;
}

.cart-view-container .tab {
  cursor: pointer;
  color: #9D9D9D;
  font-size: 16px;
  font-weight: 500;
  margin: 0 4px;

}

.div-text-bold {
  padding: 10px;
  font-weight: bold;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.cart-view-container {
  max-width: 565px;
  margin: 0 auto 50px auto;
}

.cart-view-container .tab-active {
  cursor: pointer;
  color: rgba(140, 195, 88, 1);
  font-size: 16px;
  font-weight: bold;
  border-bottom: solid 4px #8CC358;
  margin: 0 4px;
}

.cart-view-container .tab {
  cursor: pointer;
  color: #9D9D9D;
  font-size: 16px;
  font-weight: 500;
  margin: 0 4px;

}

.delivery__card {
  background: #f5f5f5 !important;
  box-shadow: none !important;
  border-radius: 5px !important;
}

.delivery__card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.delivery__card-header h4 {
  font-size: 18px;
  margin-bottom: 0;
}

.delivery__card-header .MuiRadio-colorPrimary {
  margin-top: -9px;
  margin-right: -9px;
  margin-bottom: -9px;
}

.delivery__card-action .MuiIconButton-root {
  padding: 8px;
}

.text_tutorial_login {
  text-align: center;
  margin: auto;
  font-size: 24px;
  /*font-weight: bold;*/
  color: rgb(140, 195, 88);
  padding: 20px;
}

.loin_logout_header {
  background: white;
  border-radius: 8px;
}

.pl-20 {
  padding-left: 20px;
}

.noRadius {
  border-radius: 0 !important;
}

@media screen and (max-width: 600px) {
  .text_tutorial_login {
    font-size: 18px;
  }

  .mobile-pr-20 {
    padding-right: 20px;
  }

}
